.playerWrapper{
    width: 100%;
    margin: 20px 0%;
    height: auto;
    position: relative;
}
.middlearea img#backward,.middlearea img#forward{
    margin: 20px 10px;
    width: 45px;
    height:45px;
}
.middlearea img#play,.middlearea img#pause{
    width: 110px;
    height: 110px;
    margin: -10px 15px 0 15px;
}
.playback_options.hide{
display: none;
}
.playback_options{
    width:auto;
    height: auto;
    padding: 10px 0px;
    position: absolute;
    margin: -240px 0 0px 74%;
    border-radius: 4px;
}
.playback_options li{
    list-style-type: none;
    color: #222;
    background-color: #fff;
    padding: 10px 15px;
    text-align: center;
    font-size: 20px;
    letter-spacing: 1px;
}
.playback_options li:hover{
background-color: #0099e3;
} 
h2{
    padding: 0px 10px;
    font-size: 26px;
}
.fa-angle-left{
    font-size: 30px;
    padding: 2px 10px;
    color: #fff;
}
.fa-play,.fa-pause{
    font-size: 60px;
    padding: 0px 60px;
    color: #d90606;
    opacity: .8;
}
.fa-forward,.fa-backward{
    font-size: 38px;
    padding: 10px 10px;
    color: #d90606;
    opacity: .8;
}
.fa-play:hover ,.fa-pause:hover ,.fa-forward:hover ,.fa-backward:hover {
    opacity: 1;
}

.slider{
    width: 100%;
}
.button{
    border:1px solid #ccc;
}
.bottom{
    display: flex;
    padding: 0px;
    margin: 0px;
    
}
.bottom img{
    margin: 5px 20px 0 5px;
    width: 28px;
    height: 28px;
}
.bottom .fa,.bottom .fas{
    padding: 5px 25px 10px 10px;
    color: #ccc;
    font-size: 24px;
}
.bottom .fa:hover ,.bottom .fas:hover{
    color: #fff;
}
.bottom .spacer{
    width:65%;   
}
.bottom p{
    color: #ccc;
    width: 12%;
    padding: 0px 0px 0px 0px;
    margin: 0 0 0 0;
    font-size: 24px;
}
.bottom .playback{
    margin: 0px 0px 10px 0px;
    width: 50px;
}

.options{
    background-color:rgba(0,0,0,0.6);
    z-index: 99999;
    width: 50%;
    margin: 20% 25%;
    position: absolute;
    border-radius: 12px;
    padding: 5px 10px;
}
.options .col-md-6:nth-child(1) ul li{
    margin:  5px 10px;
    font-size: 22px;
}
.options .col-md-6 p{
    font-size: 20px;
    color: #d90606;
    padding: 10px;
}
.options .col-md-6 li{
    padding: 0px 0px 0px 10px;
    display: flex;
    color: #fff;
}
.options .row button{
background-color: #d90606;
color :#fff;
width: 20%;
margin: 20px 40%;
}
.options .row .col-md-6 li .check{
margin: 0px 0px 0px 0px;
padding: 0px 10px;
float: right;
}


@media screen and (max-width: 1600px) {
    .playerWrapper{
        width: 80%;
        margin: 20px 10%;
        height: auto;
        position: relative;
    }
    
    h2{
        padding: 0px 10px;
        font-size: 26px;
    }
    .fa-angle-left{
        font-size: 30px;
        padding: 2px 10px;
    }
    .fa-play,.fa-pause{
        font-size: 60px;
        padding: 0px 60px;
        color: #d90606;
        opacity: .8;
    }
    .fa-forward,.fa-backward{
        font-size: 38px;
        padding: 10px 10px;
        color: #d90606;
        opacity: .8;
    }
    .fa-play:hover ,.fa-pause:hover ,.fa-forward:hover ,.fa-backward:hover {
        opacity: 1;
    }
    
    .slider{
        width: 100%;
    }
    .button{
        border:1px solid #ccc;
    }
    .bottom{
        display: flex;
        padding: 0px;
        margin: 0px;
        
    }
    
    .bottom .fa,.bottom .fas{
        padding: 5px 25px 10px 10px;
        color: #ccc;
        font-size: 24px;
    }
    .bottom .fa:hover ,.bottom .fas:hover{
        color: #fff;
    }
    .bottom .spacer{
        width:75%;   
    }
    .bottom p{
        color: #ccc;
        width: 14%;
        padding: 0px 0px 0px 0px;
        margin: 0 0 0 0;
        font-size: 24px;
    }
    .bottom .playback{
        margin: 0px 15px 10px 0px;
    }
    
    .options{
        background-color:rgba(0,0,0,0.6);
        z-index: 99999;
        width: 50%;
        margin:15% 25%;
        position: absolute;
        border-radius: 12px;
        padding: 5px 10px;
    }
    .options .col-md-6:nth-child(1) ul li{
        margin:  5px 10px;
        font-size: 22px;
    }
    .options .col-md-6 p{
        font-size: 20px;
        color: #d90606;
        opacity: 1;
        padding: 10px;
    }
    .options .col-md-6 li{
        padding: 0px 0px 0px 10px;
        display: flex;
        color: #fff;
    }
    .options .row button{
    background-color: #d90606;
    color :#fff;
    width: 20%;
    margin: 20px 40%;
    }
    .options .row .col-md-6 li .check{
    margin: 0px 0px 0px 0px;
    padding: 0px 10px;
    float: right;
    }
    
}
@media screen and (max-width: 600px){
    .playerWrapper{
        width: 100%;
        height: 300px;
        position: relative;
        margin:20px 0px 0px 0px;
        padding: 0;
    }
    h2{
        padding: 0px 10px;
        font-size: 22px;
    }
    .fa-angle-left{
        font-size: 22px;
        padding: 2px 10px;
    }
    .fa-play,.fa-pause{
        font-size: 34px;
        padding: 5px 30px;
        color: #d90606;
        opacity: .8;
    }
    .fa-forward,.fa-backward{
        font-size: 22px;
        padding: 10px 10px;
        color: #d90606;
        opacity: .8;
    }
    .fa-play:hover ,.fa-pause:hover ,.fa-forward:hover ,.fa-backward:hover {
        opacity: 1;
    }
    
    .slider{
        width: 100%;
    }
    .button{
        border:1px solid #ccc;
    }
    .bottom{
        display: flex;
        padding: 0px;
        margin: 0px;
    }
    
    .bottom .fa,.bottom .fas{
        padding: 0px 20px 10px 0px;
        color: #ccc;
        font-size: 18px;
    }
    .bottom .fa:hover ,.bottom .fas:hover{
        color: #fff;
    }
    .bottom .spacer{
        width:20%;
    }
    .bottom .playback{
        margin: -5px 0px 10px 0px;
        width: 50px;
    }
    .bottom p{
        color: #ccc;
        width: 30%;
        padding: 0px 0px 10px 0px;
        margin: -5px 0 0 0;
        font-size: 17px;
    }
    
    
    .options{
        background-color:rgba(0,0,0,0.6);
        z-index: 99999;
        width: 95%;
        height:auto;
        margin:20% 1%;
        position: absolute;
        border-radius: 12px;
        padding: 5px 0px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
 

    .options .col-md-6:nth-child(1) ul li{
        margin:  5px 10px;
        font-size: 22px;
    }
    .options .col-md-6 p{
        font-size: 18px;
        color: orange;
        padding: 10px;
    }
    .options .col-md-6 li{
        padding: 0px 0px 0px 10px;
        display: flex;
        color: #fff;
    }
    .options .row button{
    background-color: #d90606;
    color :#fff;
    width: 20%;
    margin: 20px 40%;
    }
    .options .row .col-md-6 li .check{
    margin: 0px 0px 0px 0px;
    padding: 0px 10px;
    float: right;
    }
    
}
@media  (max-width: 380px){
    .bottom p{
        color: #ccc;
        width: 40%;
        padding: 0px 0px 10px 0px;
        margin: -5px 0 0 0;
        font-size: 16px;
    }
    .bottom .spacer{
        width:0%;
    }
    .bottom .fa,.bottom .fas{
        padding: 0px 15px 10px 0px;
        color: #ccc;
        font-size: 18px;
    }
    .bottom .playback{
        margin: -5px 5px 10px 0px;
    }
    
}

@media screen and (max-height: 450px) {
    .playback_options{
        width:auto;
        height: auto;
        padding: 10px 0px;
        position: absolute;
        margin: -240px 0 0px 60%;
        border-radius: 4px;
    }
    .bottom .playback{
        margin: -6px 0px 10px 0px;
        width: 60px;
    }
    .playerWrapper{
        position: relative;
        width: 100%;
        height:405px;
        padding: 0;
        margin:0;
    }
   
    h2{
        padding: 0px 10px;
        font-size: 22px;
    }
    .fa-angle-left{
        font-size: 22px;
        padding: 2px 10px;
    }
    .fa-play,.fa-pause{
        font-size: 60px;
        padding: 0px 60px;
        color: #d90606;
        opacity: .8;
    }
    .fa-forward,.fa-backward{
        font-size: 38px;
        padding: 10px 10px;
        color: #d90606;
        opacity: .8;
    }
    .fa-play:hover ,.fa-pause:hover ,.fa-forward:hover ,.fa-backward:hover {
        opacity: 1;
    }
    
    .slider{
        width: 100%;
    }
    .button{
        border:1px solid #ccc;
    }
    .bottom{
        display: flex;
        padding: 0px;
        margin: 0px;
    }
    
    .bottom .fa,.bottom .fas{
        padding: 0px 25px 10px 10px;
        color: #ccc;
        font-size: 22px;
    }
    .bottom .fa:hover ,.bottom .fas:hover{
        color: #fff;
    }
    .bottom .spacer{
        width:50%; 
    }
    .bottom p{
        color: #ccc;
        width: 20%;
        padding: 0px 0px 10px 0px;
        margin: -5px 0 0 0;
        font-size: 22px;
    }
    
    .options{
        background-color:rgba(0,0,0,0.6);
        z-index: 99999;
        width: 60%;
        height: 300px;
        margin:20px 20%;
        position: absolute;
        border-radius: 12px;
        padding: 5px 10px;
        overflow-y: scroll;
    }
    .options .col-md-6:nth-child(1) ul li{
        margin:  5px 10px;
        font-size: 22px;
    }
    .options .col-md-6 p{
        font-size: 18px;
        color: orange;
        padding: 10px;
    }
    .options .col-md-6 li{
        padding: 0px 0px 0px 10px;
        display: flex;
        color: #fff;
        font-size: 2rem;
    }
    .options .row button{
    background-color: #d90606;
    color :#fff;
    width: 20%;
    margin: 20px 40%;
    }
    .options .row .col-md-6 li .check{
    margin: 0px 0px 0px 0px;
    padding: 0px 10px;
    float: right;
    }
    
    
}