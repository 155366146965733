/*landing page css*/
.landing {
    position: relative;
    margin-bottom: -30px;
}
.landing h2{
    color: #fff;
    padding: 0px;
    margin: 15px 2%;
    padding: 0px 0 5px 0;
    border-bottom: 3px dotted #d90606;
    width: 64%;
}
.landing h2 img{
    padding: 0;
    margin: -10px 10px 0 0px;
}
.landing .container.adds img.adv{
    width:95%;
    margin: 0 2.5%;
    padding: 0;
    height: 640px;
    border-radius: 12px;
}
.video-wrapper {
    width: 100%;
    height: auto;
    overflow: hidden;
    text-align: center;
    object-fit: cover;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
  }
  .video-wrapper  .mid{
    width: 100%;
    margin: 0% 0% 0% 0;
    height: auto;
    text-align: center;
    position: absolute;
    left: 0;
    border-radius: 12px;
    top:83%;
}
.video-wrapper  .mid h2:hover{
    border: 1px solid #fff;
}

.video-wrapper  .mid h1{
    color: #fff;
    width: 100%;
    padding:  0 10%;
    background: rgba(0,0,0,0.5);
    font-size: 35px;
    border-radius: 12px;
}
.video-wrapper  .mid h2 {
    width: 24%;
    margin: 40px 38%;
    background-color: #d90606;
    align-content: center;
    justify-content: center;
    border-radius: 32px;
    padding:15px 20px;
    z-index: 999;
}
.video-wrapper  .mid h2 a{
    font-weight: 530;
    color: #fff;
}
.video-wrapper img {
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
}
.landing{
    background-color: #000;
}
.landing h1{
    color: #fff;
    padding: 10px 0 10px 20px;
    font-size: 28px;
}
.landing p{
    color: #fff;
    padding: 0px 0 10px 20px;
    font-size: 20px;
}
.container.adds{
    display: inline-flex;
    background-color: #000;
    margin-bottom: 20px;
}
.landing  .container.adds.scrollable{
    width: 98%;
    overflow-x: scroll;
    padding: 0;
    margin: 10px 1% 0 1%; 
    height: auto;
}
.landing  .container.adds.scrollable::-webkit-scrollbar{
    display: none;
}
.landing  .container.adds.scrollable img{
    width: 420px;
    height: 480px;
    border-radius: 6px;
    margin: 0px;
}
.landing  .container.adds.scrollable iframe{
    margin-right: 10px;
}
.landing  .container.adds.scrollable.review img{
    width: 400px;
    height:220px;
    border-radius: 6px;
    margin: 0px 10px;
}
.container .col-md-6{
    padding: 20px;
    color: #fff;
    padding: 2% 1%;
    align-content: center;
    justify-content: center;
}
.container .col-md-6 h1{
    font-size: 43px;
    width: 100%;
    color: #d90606;
    margin-left: 0px;
    margin-bottom: 10px;
    margin-top: 6%;
    letter-spacing: 1px;
    font-weight:bold;
}
.container .col-md-6 ul li{
    margin: 7px 0 0 20px;
    list-style-type: none;
    letter-spacing: 1px;
    font-size: 22px;
}
.container .col-md-6 p{
    margin: 0   px 0 0 0px;
    letter-spacing: 1px;
    font-size: 22px;
}

.container .col-md-6 ul li:before{
    content:url('../../assets/image/inner_about_right_icon.png');
    padding: 0 5px 0 0; /* with class ModalCarrot ??*/
  }
.container.adds .col-md-6 img{
    width:90%;
    border-radius: 12px;
    height: 580px;
    margin: 0 0% 0 5%;
}
.membership.subs.addperpose{
    background-color: #000;
    margin: 0 0 2% 0;
    width: 100%;
    padding: 0;
    display: inline-block;
}
.membership.subs.addperpose li{
    width: 350px;
}
.membership.subs.addperpose li .membership_col{
    width: 100%;
    margin:0 0 10px 10px;
    border-radius: 2px;
    border-radius: 6px;
    height: 430px;
}
@media screen and (max-width:600px){
    .video-wrapper  .mid{
        top:78%;
    }
    .video-wrapper  .mid h2 {
        width: 90%;
        margin: 20px 5%;
        background-color: #d90606;
    }
    .landing h2{
        color: #fff;
        padding: 0px;
        margin: 15px 0 0 0;
        padding: 0px 5px 5px 5px;
        border-bottom: 3px dotted transparent;
        width:100%;
        text-align:center;
    }
    .container.adds{
        display: inline-block;
        background-color: #000;
        margin-bottom: 20px;
    }
    .container.adds .col-md-6 img{
        width:100%;
        border-radius: 12px;
        height: 380px;
        margin: 20px 0% 0 0%;
    }
    .landing  .container.adds.scrollable{
        width: 100%;
        margin: 10px 0 0 0; 
        height: auto;
        display: inline-flex;
    }
    .container .col-md-6{
        padding: 20px;
        color: #fff;
        padding: 0;
        align-content: center;
        justify-content: center;
    }
    .container .col-md-6 h1{
        font-size: 32px;
        width: 100%;
        color: #d90606;
        margin-left: 0px;
        margin-bottom: 10px;
        margin-top: 6%;
        letter-spacing: 1px;
        font-weight:bold;
        padding: 0;
    }
    .container .col-md-6 ul li{
        margin: 7px 0 0 0px;
        list-style-type: none;
        letter-spacing: 1px;
        font-size: 20px;
    }
    .container .col-md-6 p{
        margin: 0px 0 0 0px;
        letter-spacing: 1px;
        font-size: 20px;
        padding: 0;
    }
    .membership.subs.addperpose{
        background-color: #000;
        margin: 0 0 2% 0;
        width: 100%;
        padding: 0;
    }
    .membership.subs.addperpose li{
        width: 95%;
    }
    .membership.subs.addperpose li .membership_col{
        width: 100%;
        margin:0 0 10px 10px;
        border-radius: 2px;
        border-radius: 6px;
        height: 430px;
    }
    .landing  .container.adds.scrollable.review img{
        width: 360px;
        height:200px;
        border-radius: 6px;
        margin: 0px 10px;
    }
    .landing h2 img{
        padding: 0;
        margin: -12px 2px 0 0px;
    }
}

@media screen and (max-width:390px){
    .video-wrapper  .mid h2 {
        width: 90%;
        margin: 30px 5%;
        background-color: #d90606;
    }
}

@media screen and (min-height:800px){
    .video-wrapper  .mid h2 {
        width: 90%;
        margin: 50px 5%;
        background-color: #d90606;
    }
}